<template>
    <k-object-selector
        :value="value"
        :label="label"
        @input="changed"
        :itemFunc="itemFunc"
        :valueSelector="valueSelector"
        :suggestionsFunc="suggestionsFunc"
        objectName="connection method"
        objectNamePlural="connection methods"
    >
        <template slot="item" slot-scope="{item}">
            <v-list-item-avatar>
              <v-img v-if="item.banking_institution" :src="item.banking_institution.meta._image_url" style="background-color: white"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title><code>{{ item.provider_name }}</code> {{ item.provider }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </k-object-selector>
</template>

<script>
import api from '@/services/api';

export default {
  props: ['value', 'label'],
  methods: {
    changed(value) {
      this.$emit('input', value);
    },
    itemFunc(id) {
      return new Promise((resolve, reject) => {
        api.kernel.get(`/banking_connection_methods/${id}`).then((data) => {
          resolve(data.data);
        }, () => {
          reject();
        });
      });
    },
    valueSelector(value) {
      return value.id;
    },
    suggestionsFunc(search) {
      if (!search) {
        return api.kernel.get('/banking_connection_methods?state=active&expand=banking_institution').then((data) => data.data.filter((d) => d.state === 'active'));
      }
      return api.kernel.get(`/banking_connection_methods/?provider_name=${search}&expand=banking_institution`).then((data) => data.data.filter((d) => d.state === 'active'));
    },
  },

};
</script>
